* {
  margin: 0px;
  padding: 0px;
}

@font-face {
  font-family: rosemery;
  src: url("./font/rosemery/DroidSans.ttf");
}

@font-face {
  font-family: cooljazz;
  src: url("./font//cooljazz/Cooljazz.ttf");
}

@font-face {
  font-family: brushMt;
  src: url("./font/brushMt/BRUSHSCI.ttf");
}

@font-face {
  font-family: chocooky;
  src: url("./font/chococooky/Chococooky.ttf");
}
nav {
  font-family: cooljazz;
  background: rgb(255, 0, 255) !important;
}

.input-group-text {
  font-family: rosemery !important;
}

.social-icons {
  display: flex;
  justify-content: space-around;
}

.chocookyFont {
  font-family: chocooky;
}

.brushmtFont {
  font-family: brushMt;
}

.rosemeryFont {
  font-family: rosemery;
}

.heading-marchant-list {
  font-family: brushMt;
}

.business-name {
  font-family: rosemery;
  font-size: 25px;
}

.carousel-list {
  margin: 0px auto;
}

.corousel-one {
  margin: 10px 400px;
}

.merchant-details {
  margin-top: 10px;
  color: magenta;
  overflow: hidden;
}

.merchant-details h1 {
  font-family: brushMt;
}

.merchant-details h5 {
  font-family: chocooky;
}
.merchant-details h5 b {
  font-family: rosemery;
}

.leaflet-container {
  width: 100%;
  height: 70vh;
}
@media only screen and (max-width: 600px) {
  .heading-name-container {
    position: relative;
    width: 100%;
  }
}

@media only screen and (min-width: 600px) {
  .heading-name-container {
    position: relative;
    width: 50%;
  }
}

@media only screen and (min-width: 768px) {
  .heading-name-container {
    position: relative;
    width: 30%;
  }
}

@media only screen and (min-width: 992px) {
  .heading-name-container {
    position: relative;
    width: 30%;
  }
}

@media only screen and (min-width: 1200px) {
  .heading-name-container {
    position: relative;
    width: 30%;
  }
}

.name-heading {
  font-family: rosemery;
}
#actionbtn {
  display: none;
  color: white;
  padding: 2px 20px;
  font-size: 20px;
  background-color: magenta;
  opacity: 90%;
  border: 0px;
  width: 100%;
  font-family: cooljazz;
  border-radius: 5px;
  position: absolute;
}
.heading-name-container:hover #actionbtn {
  display: inline;
  position: relative;
  right: 0%;
  bottom: 0%;
}

.product-list {
  font-family: rosemery;
}

.product-review {
  font-family: chocooky;
  color: cyan;
  font-weight: lighter;
}

.review {
  margin: 0;
  font-family: rosemery;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.comment-heading {
  font-family: rosemery;
  color: magenta;
}

.prop-entry {
  position: relative;
  overflow: hidden;
}
.prop-entry img,
.prop-entry figure {
  margin-bottom: 0;
}
.prop-entry .prop-text {
  position: absolute;
  bottom: -50px;
  width: 100%;
  z-index: 2;
  -webkit-transition: 0.3s all ease;
  -o-transition: 0.3s all ease;
  transition: 0.3s all ease;
}
.prop-entry .prop-text .inner {
  padding-left: 20px;
  padding-right: 20px;
}
.prop-entry .prop-text:before {
  z-index: -1;
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: -moz-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    color-stop(18%, transparent),
    color-stop(99%, rgba(0, 0, 0, 0.8)),
    to(rgba(0, 0, 0, 0.8))
  );
  background: -o-linear-gradient(
    top,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  background: linear-gradient(
    to bottom,
    transparent 0%,
    transparent 18%,
    rgba(0, 0, 0, 0.8) 99%,
    rgba(0, 0, 0, 0.8) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#cc000000',GradientType=0 );
}
.prop-entry .prop-text .prop-more-info {
  font-size: 12px;
  background: #f89d13;
  position: relative;
  z-index: 4;
  padding-top: 5px;
  padding-bottom: 5px;
}
.prop-entry .prop-text .prop-more-info .col {
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;
  color: #fff;
}
.prop-entry .prop-text .prop-more-info span {
  display: block;
}
.prop-entry .prop-text .price {
  background: #f89d13;
  color: #fff;
  padding: 4px 10px;
  display: inline-block;
  margin-bottom: 20px;
}
.prop-entry .prop-text .title {
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 0;
}
.prop-entry .prop-text .location {
  font-size: 15px;
  color: #e6e6e6;
}
.prop-entry:hover .prop-text {
  bottom: 0;
}
